$(document).ready(function() {
	// console.log('we are connected');

	// add class to navbar when we scroll
	$(window).scroll(function() {
		if ($(document).scrollTop() > 50) {
			$('nav').addClass('shrink');
			$('#sidebar').addClass('up');
		} else {
			$('nav').removeClass('shrink');
			$('#sidebar').removeClass('up');
		}
	});
	// set the scrollSpy offset for pages with sidebar
	if ($("#sidebar").length > 0) {
		// set width on our FIXED sidebar
		var sideBarNavWidth = $('#sidebar').width() - parseInt($('#sidebar').css('paddingLeft')) - parseInt($('#sidebar').css('paddingRight'));
		$('#sidebar .nav').css('width', sideBarNavWidth);
		// highlight active items in sidebar
		var offsetHeight = $('#header').height();
		$('body').scrollspy({
			offset: offsetHeight
		});
	};
	// animate scroll to section within page
	$("#sidebar .nav.internal-links a, a.internal-link").on('click', function(e) {
		e.preventDefault();
		// get our target ID
		var target = $(this).attr('href');
		var targetPosition = $(target).offset().top;
		var targetPosition = targetPosition - 50;
		var scrollSpeed = 525;
		if ($(this).hasClass('slow')) var scrollSpeed = 2500;
		// scroll
		$('html, body').animate({
			scrollTop: targetPosition
		}, scrollSpeed );
	});
	// Toggle - generic
	$('.toggle-control').on('click', function(e){
		e.preventDefault();
		var target = $(this).attr('data-target-toggle');
		$(this).addClass('active');
		$(target).addClass('active');
		if($(this).hasClass('sibs')) {
			$(this).siblings('.toggle-control').removeClass('active');
			$(target).siblings('.toggle-content').removeClass('active');
		};
	});
	// Closable Sections - generic
	$('body').on('click', '.closable-control', function(e){
		e.preventDefault();
		var target = $(this).attr('data-target-closable');
		$(target).slideToggle().toggleClass('closed');
		if(!$(target).hasClass('closed')) {
			// setTimeout used to allow the content time to display (we can't get a distance to a display:none element)
			setTimeout(function(){
				var distance = $(target).offset().top;
				$('html, body').animate({ scrollTop: distance }, 500);
			}, 10);
		};
	});

	// Modals - for videos
	// --- on close
	$('.modal.for-video').on('hide.bs.modal', function (e) {
		var video = $(this).find('iframe');
		// reset the src to stop/reset the video
		$(video).attr('src', $(video).attr('src'));
	});

	// Carousel - generic
	$('.owl-carousel').owlCarousel({
		loop:true,
		items:1,
		autoplay:true,
		autoplayTimeout:8888,
		autoplaySpeed:1500,
		navSpeed:1500,
		nav:true,
		navText:[
			"<span class='fa fa-chevron-left'></span>",
			"<span class='fa fa-chevron-right'></span>"
		],
	});
	// Validate Email Address
	function validateEmail(email) {
		var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
		return regex.test(email);
	}

	// Forms - toggle to next step
	$('.step .next').on('click', function(e){
		e.preventDefault();
		var target = $(this).attr('data-target-step');
		var current = $(this).closest('.step');
		var emailField = $(current).find('.email-required');
		if ($(emailField).length >= 1) {
			var emailAddress = $(emailField).val();
			if( !validateEmail( emailAddress ) ) {
				$(emailField).next('.error-message').show();
				return false;
			}
		}
		$(current).removeClass('active');
		$(target).addClass('active');
		$('form .wrapper').animate({ scrollTop: 0 }, 0);
	});
	$('.step .back').on('click', function(e){
		e.preventDefault();
		var target = $(this).attr('data-target-step');
		var current = $(this).closest('.step');
		$(current).removeClass('active');
		$(target).addClass('active');
		// reset wrapper to top
		$('form .wrapper').animate({ scrollTop: 0 }, 0);
	});
	// Show "placeholder" select option
	$('form.modern select').on('change', function(){
		var selected = $(this).find('option:selected');
		if (!$(selected).hasClass('placeholder')) {
			console.log('picked a good one');
			$(this).removeClass('waiting');
		} else {
			console.log('still waiting');
			$(this).addClass('waiting');
		};
	});
	// Limit Characters in a Field
	$('.limit-char').keyup(function(e) {
		var chars = $(this).val(),
			charLength = chars.length,
			charLimit = $(this).attr('data-char-limit'),
			charLimit = parseInt(charLimit),
			charRemain = parseInt(charLimit - charLength + 1);
		if (charRemain <= 0) {
			$(this).val((chars).substring(0, charLength - 1));
			$(this).next('.error-message').slideDown();
		} else {
			$(this).next('.error-message').slideUp();
		};
	});
	// DatePicker - generic
	$('.datepicker').bootstrapMaterialDatePicker({
		format: 'l',
		time: false,
		clearButton: false,
		nowButton: false,
		switchOnClick: true
	});
	// DatePicker - birthday (min 18 years)
	$('.birthdaypicker').bootstrapMaterialDatePicker('setMaxDate', moment().subtract(18, 'years'));
	// TimePicker - generic
	$('.timepicker').bootstrapMaterialDatePicker({
		shortTime: true,
		format: 'LT',
		date: false,
		clearButton: false,
		nowButton: false,
		switchOnClick: true
	});

	// DEMO SEARCH RESULTS
	$('form .search-field').on('keyup', function(){
		var logo = $(this).closest('.step').find('.logo');
		var results = $(this).closest('.step').find('.results');
		if($(this).val() !== ""){
			$(logo).slideUp(333);
			$(results).slideDown(333);
		} else {
			$(logo).slideDown(333);
			$(results).slideUp(333);
		};
	});

	// preview image before submitting pictures
	function readURL(input) {
		if (input.files && input.files[0]) {
			var reader = new FileReader();
			reader.onload = function (e) {
				$('.step.picture').css('background-image', 'url("'+e.target.result+'")');
			}
			reader.readAsDataURL(input.files[0]);
		}
	}
	$("#receipt").change(function(){
		readURL(this);
	});

	// ensure fields match
	$('.confirm-field').on('keyup', function() {
		var match = $(this).attr('data-match');
		var match = $(match).val();
		var value = $(this).val();
		if(value !== match) {
			$(this).closest('.form-group').addClass('has-error');
		} else{
			$(this).closest('.form-group').removeClass('has-error');
		};
	});

	// set cookie for claiming business (2 day life) (should be done after form is successfully submitted)
	$('.set-cookie_claim-business').on('click', function(e){
		e.preventDefault();
		createCookie('claimed',true,2);
		// DEMO ONLY: refresh the page since we're not processing a form
			window.location.reload();
	});

}); // end - document ready

$(window).load(function() {

	// scroll down to form if validation fails
	if ($('#scroll-to-errors').length > 0) {
		console.log('we got issues.');
		// get our target ID
		var target = $('#scroll-to-errors').attr('data-target');
		var targetPosition = $(target).offset().top;
		var targetPosition = targetPosition - 50;
		// scroll
		$('html, body').animate({
			scrollTop: targetPosition
		}, 1000 );
	};

}); // end - window load